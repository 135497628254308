<template lang="pug">
    v-bottom-navigation.custom-bottom-nav__nav(app scroll-target="#scroll-area-1" hide-on-scroll="" absolute="" horizontal="")
        v-btn( v-for="item in itemsBottomNavBussiness" :key="item.id"  @click='goTo(item.to)')
            .custom-arc
            img(:src="item.icon" class="icon-normal" width="35px" height="auto")
            img(:src="item.iconActive" class="icon-active")
</template>
<script>
  export default {
    name: 'BottomNav',
    data () {
      return {
        itemsBottomNavBussiness: [
          {
            to: '/',
            name: '',
            icon: require('@/assets/images/icon1.png'),
            iconActive: require('@/assets/images/icon1_active.png'),
            authRequired: false,
          },
          {
            to: '/videos',
            name: '',
            icon: require('@/assets/images/icon_video.png'),
            iconActive: require('@/assets/images/icon_video_active.png'),
            authRequired: true,
          },
          {
            to: '/search',
            name: '',
            icon: require('@/assets/images/icon3.png'),
            iconActive: require('@/assets/images/icon3.png'),
            authRequired: false,
          },
          {
            to: '/notifications',
            name: '',
            icon: require('@/assets/images/icon4.png'),
            iconActive: require('@/assets/images/icon4_active.png'),
            authRequired: true,
          },
          {
            to: '/profile',
            name: '',
            icon: require('@/assets/images/icon5.png'),
            iconActive: require('@/assets/images/icon5_active.png'),
            authRequired: true,
          },
        ],
      }
    },
    computed: {
      user () {
        return this.$store.state.users.user
      },
    },
    methods: {
      validateRoute (item) {
        // console.log(item.authRequired && !!this.user)
        return (item.authRequired && !!this.user)
      },
      openDialog (dialog) {
        this.$store.dispatch('dialogs/openDialog', dialog).then(function () {
          // console.log('dialog set on : ' + dialog)
        })
      },
      goTo (to) {
        // console.log(to)
        if (!this.user) {
          switch (to) {
            case '/videos':
              this.openDialog('login')
              break
            case '/notifications':
              this.openDialog('login')
              break
            case '/profile':
              this.openDialog('login')
              break
            default:
              this.$router.replace(to)
              break
          }
        } else {
          this.$router.replace(to)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
.v-btn--active{
  background: #a3a3a3 !important;
}
</style>
